import { useGetUniqueCampaignsPlatformQuery, useGetSpendBreakdownPlatformQuery, useGetClicksCpsBreakdownPlatformQuery, useGetConversionsCpaBreakdownPlatformQuery, useConversionsCpaBreakdownTablePlatformQuery} from "../../redux/api/adsReporting";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Select } from "antd";
import PlotContainer from "../PlotContainer";
import DateGranularityDropdown from "../../components/UI/DateGranularityDropdown";
import TableContainer from "../TableContainer";
import CampaignComparison from "./CampaignComparison";
import PeriodOnPeriodTable from "./PeriodOnPeriodTable";
import TooltipContainer from "../../components/UI/TooltipContainer";

export default function Reporting({platform}) {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const granularity = useSelector((state) => state.genericReporting.dateGranularity);

    const [campaignOptions, setCampaignOptions] = useState([]);
    const [selectedCampaigns, setSelectedCampaigns] = useState([]);

    const uniqueCampaigns = useGetUniqueCampaignsPlatformQuery({
        client: selectedClient,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        platform: platform
    });

    useEffect(() => {
        if (uniqueCampaigns.status === 'fulfilled') {
            setCampaignOptions(uniqueCampaigns.data.map(
                (campaign) => {
                    return {
                        label: campaign,
                        value: campaign
                    }
                }

            ));
            setSelectedCampaigns(uniqueCampaigns.data);
        }
        else {
            setCampaignOptions([]);
            setSelectedCampaigns([]);
        }
    }, [uniqueCampaigns]);

    return (
        <div>
            <h1>{platform[0].toUpperCase() + platform.slice(1)} Reporting</h1>
            <div className='flexible-container'>
                <h3>Select Campaigns <TooltipContainer title='Select Campaigns'></TooltipContainer></h3>
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    value={selectedCampaigns}
                    onChange={(value) => {
                        setSelectedCampaigns(value);
                    }}
                    options={campaignOptions}
                >
                </Select>
                <h3>Select Date Granularity <TooltipContainer title='Date Granularity'></TooltipContainer></h3>
                <DateGranularityDropdown />
            </div>
            <br></br>
            <div className='flexible-container'>
                <div style={{
                    'width': '100%',
                    'height': '80vh'
                }}>
                    <PlotContainer tooltipTitle='Spend Breakdown by Campaign' useApi={useGetSpendBreakdownPlatformQuery} useApiArgs={{
                        client: selectedClient,
                        startDate: selectedStartDate,
                        endDate: selectedEndDate,
                        campaigns: selectedCampaigns,
                        platform: platform,
                        granularity: granularity
                    }} />
                </div>
            </div>
            <div className="full-width-container">
                <PlotContainer tooltipTitle='Clicks vs CPC' useApi={useGetClicksCpsBreakdownPlatformQuery} useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    selectedCampaigns: selectedCampaigns,
                    granularity: granularity,
                    platform: platform
                }} />
            </div>
            <div className="full-width-container">
                <PlotContainer tooltipTitle='Conversions vs CPA' useApi={useGetConversionsCpaBreakdownPlatformQuery} useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    selectedCampaigns: selectedCampaigns,
                    granularity: granularity,
                    platform: platform
                }} />
            </div>
            <CampaignComparison platform={platform} selectedCampaigns={selectedCampaigns} />
            <PeriodOnPeriodTable platform={platform} selectedCampaigns={selectedCampaigns} />
            <div className="full-width-container">
                <TableContainer tooltipTitle="Daily Metrics Table" useApi={useConversionsCpaBreakdownTablePlatformQuery} apiParams={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    selectedCampaigns: selectedCampaigns,
                    granularity: granularity,
                    platform: platform
                }} />
            </div>
        </div>
    )
}