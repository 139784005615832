import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import IsRollingSwitch from '../../components/UI/IsRollingSwitch';

const initialState = {
    selectedClient: "",
    startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    endDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    countryFilter: "All",
    gaDeviceCategoriesFilter: "All",
    gaSourceFilter: "All",
    gaLandingPageFilter: "All",
    dateGranularity: "day",
    selectedConfig: null,
    rollingWindow: 7,
    IsRolling: false,
    bulkUploaderAccessToken: null,
    bulkUploaderFacebookAccessToken: null,
    bulkUploaderSelectedClientId: null,
    bulkUploaderSelectedJobName: null
};

const genericReportingSlice = createSlice({
    name: 'genericReporting',
    initialState,
    reducers: {
        setSelectedClient: (state, value) => {
            state.selectedClient = value.payload;
        },
        setStartAndEndDate: (state, value) => {
            console.log('value is')
            console.log(value.payload)
            state.startDate = value.payload[0];
            state.endDate = value.payload[1];
        },
        setCountryFilter: (state, value) => {
            state.countryFilter = value.payload;
        },
        setGaDeviceCategoriesFilter: (state, value) => {
            state.gaDeviceCategoriesFilter = value.payload;
        },
        setGaSourceFilter: (state, value) => {
            state.gaSourceFilter = value.payload;
        },
        setGaLandingPageFilter: (state, value) => {
            state.gaLandingPageFilter = value.payload;
        },
        setDateGranularity: (state, value) => {
            state.dateGranularity = value.payload;
        },
        setSelectedConfig: (state, value) => {
            state.selectedConfig = value.payload;
        },
        setRollingWindow: (state, value) => {
            state.rollingWindow = value.payload;
        },
        setIsRolling: (state, value) => {
            state.IsRolling = value.payload;
        },
        setBulkUploaderAccessToken: (state, value) => {
            state.bulkUploaderAccessToken = value.payload;
        },
        removeBulkUploaderAccessToken: (state) => {
            state.bulkUploaderAccessToken = null;
        },
        setBulkUploaderFacebookAccessToken: (state, value) => {
            state.bulkUploaderFacebookAccessToken = value.payload;
        },
        removeBulkUploaderFacebookAccessToken: (state) => {
            state.bulkUploaderFacebookAccessToken = null;
        },
        setBulkUploaderSelectedClientId: (state, value) => {
            state.bulkUploaderSelectedClientId = value.payload;
        },
        setBulkUploaderSelectedJobName: (state, value) => {
            state.bulkUploaderSelectedJobName = value.payload;
        }
    }
});

export const {
    setBulkUploaderSelectedClientId, setBulkUploaderSelectedJobName,
    setBulkUploaderFacebookAccessToken, removeBulkUploaderFacebookAccessToken,
    setBulkUploaderAccessToken, removeBulkUploaderAccessToken,
    setSelectedClient, setStartAndEndDate, 
    setCountryFilter, setGaDeviceCategoriesFilter, setGaSourceFilter,
    setDateGranularity, setSelectedConfig, setGaLandingPageFilter,
    setRollingWindow, setIsRolling
 } = genericReportingSlice.actions;

export default genericReportingSlice.reducer;