import { combineReducers } from 'redux'

import oauthReducer from './store/oauthSlice'
import userReducer from './api/user'
import shopifyReportingReducerApi from './api/shopifyReporting'
import shopifyReportingReducer from './store/shopifyReportingSlice'
import adsReportingReducerApi from './api/adsReporting'
import genericReportingReducer from './store/genericReportingSlice'
import claimsApiReducer from './api/claims'
import compassProjectApi from './api/compassProject'
import compassSliceReducer from './store/compassSlice'
import gaFunnelingReducer from './api/gaFunneling'
import genericReportingApiReducer from './api/genericReporting'
import BulkUploaderApiReducer from './api/bulkUploader'

const rootReducer = combineReducers({
    'oauth': oauthReducer,
    'userApi': userReducer,
    'shopifyReportingApi': shopifyReportingReducerApi,
    'shopifyReporting': shopifyReportingReducer,
    'compass': compassSliceReducer,
    'adsReportingApi': adsReportingReducerApi,
    'genericReporting': genericReportingReducer,
    'claimsApi': claimsApiReducer,
    'compassProjectApi': compassProjectApi,
    'gaFunnelingApi': gaFunnelingReducer,
    'genericReportingApi': genericReportingApiReducer,
    'bulkUploaderApi': BulkUploaderApiReducer
})

export default rootReducer