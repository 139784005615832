import React from 'react';
import CompassProjects from '../containers/Compass/CompassProjects';
import CompassTabs from '../containers/Compass/CompassTabs';
import { GenericReportingDropdowns } from '../containers/GenericReportingDropdowns';
import DateGranularityDropdown from '../components/UI/DateGranularityDropdown';
import DriversDropdown from '../containers/Compass/DriversDropdown';

const Compass = () => {
    return (
        <>
            <div style={{
                'display': 'flex',
                'flexDirection': 'row',
                'justifyContent': 'center',
                'alignSelf': 'center',
                'alignItems': 'center',
            }}>
                <GenericReportingDropdowns />
            </div>
            <div style={{
                'display': 'flex',
                'flexDirection': 'column',
                'alignItems': 'center',
                'alignSelf': 'center',
                'alignItems': 'center',
                'marginTop': '1rem',
            }}>
                <div className='flexible-container' style={{
                    'display': 'flex',
                    'flexDirection': 'row',
                }}>
                    <div>
                        <h3>Filter By Date Granularity</h3>
                        <DateGranularityDropdown />
                    </div>
                    <div style={{
                    }}>
                        <h3>Filter By Drivers</h3>
                        <DriversDropdown />
                    </div>
                </div>
            </div>
        <div style={{
            alignItems: 'left',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            'alignSelf': 'center',
        }}>
            <div style={{
                float: 'right',
                height: '90vh',
                padding: '20px',
            }}>
                <CompassTabs />
            </div>
            </div>
        </>
    );
};

export default Compass;