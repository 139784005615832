import React from "react";
import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { Spin } from "antd";
import AutoSizer from "react-virtualized-auto-sizer";
import TooltipContainer from "../components/UI/TooltipContainer";

function PlotContainer(props) {

    const api = props.useApi(props.useApiArgs);
    console.log('tooltipTitle', props.tooltipTitle);
    const tooltipTitle = props.tooltipTitle ? props.tooltipTitle : 'WIP';

    const [data, setData] = useState([]);
    const [layout, setLayout] = useState({});
    const [error, setError] = useState('');

    const [showCharts, setShowCharts] = useState(true);

    useEffect(() => {
        if (api.status === 'fulfilled') {
            console.log('fulfilled')
            const parsedData = JSON.parse(api.data);
            setData(parsedData.data);
            setLayout(parsedData.layout);
            console.log(parsedData.data);
            setShowCharts(false);
            setError('');
        }
        else if (api.status === 'rejected') {
            setData([]);
            setLayout({});
            setShowCharts(true);
            setError('Error');
        }
        else if (api.status === 'pending') {
            setData([]);
            setLayout({});
            setShowCharts(false);
            setError('');
        }
        else {
            setData([]);
            setLayout({});
            setShowCharts(false);
            setError('Error');
        }
    }
        , [api]);

    return (
        <div style={{
            'width': '100%',
            'height': '100%',
            'minWidth': '30vw',
            'minHeight': '45vh',
        }}>
            {showCharts ? <></> : <>
                {data.length === 0 && Object.keys(layout).length === 0 ? <Spin
                    style={{
                        'margin': '5rem',
                        'display': 'flex',
                        'justifyContent': 'center',
                        'alignItems': 'center',
                        'justifySelf': 'center',
                        'alignSelf': 'center',
                    }}></Spin> :
                    <AutoSizer>
                {({ height, width }) => {
                            return (
                                <div style={{
                                    'width': '100%',
                                    'height': '100%',
                        }}>
                        <div style={{
                                    'display': 'flex',
                                    'margin': '0',
                                    'width': width,
                                    'height': height,
                                    'flexDirection': 'column'
                        }}>
                        <TooltipContainer title={tooltipTitle} style={{
                                        'alignSelf': 'flex-end',
                                        'margin': '0',
                                        'marginRight': '0.3vw',
                        }}></TooltipContainer>
                        <Plot
                        data={data}
                        layout={layout}
                        style={{
                            'width': width,
                            'height': height - 15,
                        }}
                        config={
                            {
                                'responsive': true,
                                'displayModeBar': true,
                                'displaylogo': false,
                                'toImageButtonOptions': {
                                    'format': 'png',
                                    'filename': 'plot',
                                    'height': 900,
                                    'width': 1600,
                                    'scale': 1
                                },
                                plotlyServerURL: 'https://chart-studio.plotly.com',
                                displayModeBar: 'hover',
                                showEditInChartStudio: true,
                             }
                        }
                                    ></Plot>
                                    </div>
                                </div>
                            )
                        }
                    }
                    </AutoSizer>
                }
            </>
            }
            <h2 style={{
                'color': 'red',
                'justifyContent': 'center',
                'alignItems': 'center',
                'display': 'flex',
                'justifySelf': 'center',
                'alignSelf': 'center',
            }}>{error}</h2>
        </div>

    )
}

export default PlotContainer;