import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { GenericReportingDropdowns } from '../../containers/GenericReportingDropdowns';
import Google from './Google';
import Facebook from './Facebook';
import Tiktok from './Tiktok';
import Bing from './Bing';
import MediaInvestmentPerformance from './MediaInvestmentPerformance';
import { useGetIfIsClientOnTikTokQuery, useGetIfIsClientOnBingQuery } from '../../redux/api/genericReporting';
import { useSelector } from 'react-redux';

function PaidMediaPage() {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const [tabs, setTabs] = useState([
        {
            label: 'Google',
            key: 'Google',
            children: [<Google></Google>]
        },
        {
            label: 'Facebook',
            key: 'Facebook',
            children: [<Facebook></Facebook>]
        }
    ]);

    const isClientOnTikTok = useGetIfIsClientOnTikTokQuery(
        {
            client: selectedClient
        }
    );

    const isClientOnBing = useGetIfIsClientOnBingQuery(
        {
            client: selectedClient
        }
    );

    useEffect(() => {

        var tmpTabs = [
            {
                label: 'Google',
                key: 'Google',
                children: [<Google></Google>]
            },
            {
                label: 'Facebook',
                key: 'Facebook',
                children: [<Facebook></Facebook>]
            }
        ];

        if (isClientOnTikTok.data) {
            tmpTabs.push({
                label: 'Tiktok',
                key: 'Tiktok',
                children: [<Tiktok></Tiktok>]
            })
        }

        if (isClientOnBing.data) {
            tmpTabs.push({
                label: 'Bing',
                key: 'Bing',
                children: [<Bing></Bing>]
            })
        }


        tmpTabs.push(
            {
                label: 'Media Investment Performance',
                key: 'Media Investment Performance',
                children: [<MediaInvestmentPerformance></MediaInvestmentPerformance>]
            }
        )

        setTabs(tmpTabs);
    }
    , [isClientOnTikTok, selectedClient, isClientOnBing]);

    return (
        // JSX code for your component's UI goes here
        <div style={{
            'display': 'flex',
            'flexDirection': 'column',
            'justifyContent': 'center',
            'alignSelf': 'center',
        }}>
            <GenericReportingDropdowns></GenericReportingDropdowns>
            <Tabs items={tabs} ></Tabs>
        </div>
    );
}

export default PaidMediaPage;