import React from 'react';
import { useSelector } from 'react-redux';
import { useGetNewCustomerVsNcpaQuery, useGetNewSubsVsCpnsQuery, useGetRollingSpendQuery, useGetAovTrendQuery } from '../../redux/api/genericReporting';
import PlotContainer from '../PlotContainer';
import '../containerStyles.css';
import ExecutiveSummaryRevenueChart from './ExecutiveSummaryRevenueChart';
import DateGranularityDropdown from '../../components/UI/DateGranularityDropdown';
import { useState, useEffect } from 'react';
import { Input } from 'antd';
import IsRollingSwitch from '../../components/UI/IsRollingSwitch';
import ExecutiveSummaryNewReturningByConfig from './ExecutiveSummaryNewReturningByConfig';

const ExecutiveSummaryCharts = () => {
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const selectedDateGranularity = useSelector((state) => state.genericReporting.dateGranularity);
    const rollingWindow = useSelector((state) => state.genericReporting.rollingWindow);

    const [isNcpaRolling, setIsNcpaRolling] = useState(false);
    const [isCpnsRolling, setIsCpnsRolling] = useState(false);

    return (
        <div>
            <div className='flex-column'>
                <div className='full-width-container'>
                    <PlotContainer useApi={useGetRollingSpendQuery} tooltipTitle='Total Spend vs 7D Rolling Total Spend' useApiArgs={{
                        client: selectedClient,
                        startDate: selectedStartDate,
                        endDate: selectedEndDate,
                        granularity: selectedDateGranularity,
                        rollingWindow: rollingWindow,
                        platform: 'Shopify'
                    }} />
                </div>
            <IsRollingSwitch isRolling={isNcpaRolling} setIsRolling={setIsNcpaRolling} />
            <div className='full-width-container' style={{
                    'marginTop': '0vh',
                    'borderRadius': '0px 10px 10px 10px'
            }}>
                <PlotContainer
                    tooltipTitle='New Customers vs NCPA'
                useApi={useGetNewCustomerVsNcpaQuery}
                useApiArgs={{
                client: selectedClient,
                startDate: selectedStartDate,
                endDate: selectedEndDate,
                granularity: selectedDateGranularity,
                isRolling: isNcpaRolling,
                rollingWindow: rollingWindow,
                platform: 'Shopify'
                    }} />
                </div>
                <IsRollingSwitch isRolling={isCpnsRolling} setIsRolling={setIsCpnsRolling} />
                <div className='full-width-container' style={{
                    'marginTop': '0vh',
                    'borderRadius': '0px 10px 10px 10px'
                }}>
                    <PlotContainer useApi={useGetNewSubsVsCpnsQuery} tooltipTitle={
                        'New Subscribers vs CPNS'
                    } useApiArgs={{
                        client: selectedClient,
                        startDate: selectedStartDate,
                        endDate: selectedEndDate,
                        granularity: selectedDateGranularity,
                        isRolling: isCpnsRolling,
                        rollingWindow: rollingWindow
                    }} />
                    </div>
                <div className='full-width-container'>
                    <PlotContainer useApi={useGetAovTrendQuery} tooltipTitle='AOV Trend' useApiArgs={{
                        client: selectedClient,
                        startDate: selectedStartDate,
                        endDate: selectedEndDate,
                        granularity: selectedDateGranularity
                    }} />
                </div>
                <ExecutiveSummaryRevenueChart />
                <ExecutiveSummaryNewReturningByConfig />
            </div>
        </div>
    );
};

export default ExecutiveSummaryCharts;