import {
    useAddAProductGroupMutation,
    useGetProductsTableQuery,
    useGetProductGroupsQuery,
    useAssignToProductGroupMutation,
    useDeleteProductGroupMutation
} from '../../redux/api/genericReporting';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spin, Select, Button, Input, Collapse } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import '../ag-grid-theme.css'; // imp

export default function ProductGroupCreationAttributionPage({ selectedConfig, platform = 'Shopify' }) {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);

    const [toCreateProductGroup, setToCreateProductGroup] = useState('');
    var [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [selectedProductGroup, setSelectedProductGroup] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);

    const table = useGetProductsTableQuery({
        client: selectedClient,
        configName: selectedConfig,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        'platform': platform
    });

    const productGroups = useGetProductGroupsQuery({
        client: selectedClient,
        configName: selectedConfig,
        'platform': platform
    });
    
    const [assignToProductGroup] = useAssignToProductGroupMutation();
    const [deleteProductGroup] = useDeleteProductGroupMutation();

    useEffect(() => {
        if (table.status == 'fulfilled') {
            console.log('table data is:')
            console.log(table.data)
            setRowData(table.data.rowData);
            setColumnDefs(table.data.columnDefs);
        }
    }
        , [table]);

    const [addProductGroup] = useAddAProductGroupMutation();

    function onAssignClick() {

        if (selectedProductGroup && selectedItems.length > 0) {
            
            var tmpSelectedItems = selectedItems.map((item) => {
                return {
                    variant_id: item.variant_id || "",
                    name: item.name || "",
                    sku: item.sku || "",
                }
            });

            assignToProductGroup({
                client: selectedClient,
                configName: selectedConfig,
                productGroup: selectedProductGroup,
                products: tmpSelectedItems,
                'platform': platform
            });
        }
        else {
            alert('Please select a product group and some products to assign');
        }
    }

    return (
        <div>
            <h1>Product Group Creation/Assignment</h1>
            <div>
                <Collapse defaultActiveKey={['1']} items={[
                    {
                        key: '1',
                        label: 'Create a new product group',
                        children: <div style={{
                            'display': 'flex',
                            'flexDirection': 'row',
                            'justifyContent': 'space-around',
                        }}>
                            <div style={{
                                'display': 'flex',
                                'flexDirection': 'column',
                                'justifyContent': 'space-around',
                            }}>
                                <h2>Create a new product group</h2>
                                <Input value={
                                    toCreateProductGroup
                                } placeholder="Product Group Name" onChange={(e) => setToCreateProductGroup(e.target.value)}></Input>
                                <br></br>
                                <Button onClick={() => {
                                    addProductGroup({
                                        client: selectedClient,
                                        configName: selectedConfig,
                                        productGroup: toCreateProductGroup,
                                        'platform': platform
                                    });
                                    setToCreateProductGroup('');
                                }}>Create</Button>
                            </div>
                        </div>
                    }
                ]}>
                </Collapse>
            <br></br>
            </div>
            <br></br>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
            }}>
                {table.isFetching ? <Spin></Spin> :
                    <div style={{
                        height: '100vh',
                        width: '60%'
                    }}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            style={{
                                height: '100vh',
                                width: '60%'
                            }}
                            rowSelection='multiple'
                            onSelectionChanged={(event) => {
                                setSelectedItems(event.api.getSelectedRows());
                            }}
                        >
                        </AgGridReact>
                    </div>
                }
                <div>
                    <h3>Select a Product Group</h3>
                    {productGroups.data && !productGroups.isFetching ? <Select style={{
                        'width': '20vw'
                    }}
                        options={productGroups.data.map((pg) => {
                            return {
                                label: pg,
                                value: pg
                            }
                        })
                        }
                        value={selectedProductGroup}
                        onChange={(value) => {
                            setSelectedProductGroup(value);
                        }}
                    ></Select> :
                        <Spin></Spin>
                    }
                    <br></br>
                    <br></br>
                    <Button onClick={onAssignClick} style={{
                        'marginLeft': '0.5vw'
                    }}>Assign Selected</Button>
                    <Button onClick={() => {
                        deleteProductGroup({
                            client: selectedClient,
                            configName: selectedConfig,
                            productGroupName: selectedProductGroup,
                            'platform': platform
                        });
                        setSelectedProductGroup(null);
                    }} style={{
                        'marginLeft': '0.5vw'
                    }}>Delete Selected Product Group</Button>
            </div>
            </div>
        </div>
    );
}
